// Check build env, default to development
const buildEnv = ['production', 'staging', 'development'].includes(process.env.NODE_ENV || 'development')
    ? process.env.NODE_ENV
    : 'development';

// env.ts will be replaced by one of development.ts, staging.ts, or production.ts on npm build script
// eslint-disable-next-line import/no-dynamic-require
const appEnv = require(`./env/${buildEnv === 'development' ? 'development' : 'env'}.ts`).default;
const defaultProtocol = appEnv.environment === 'development' ? 'http://' : 'https://';

const applicationEnv = {
    defaultLocale: 'en-GB',
    appUrl: `${defaultProtocol}${appEnv.appHostname}`,
    graphqlGatewayUrl: `${defaultProtocol}${appEnv.gqlGatewayHostname}`,
    ssoUrl: `${defaultProtocol}${appEnv.ssoHostname}`,
    qs2Url: `${defaultProtocol}${appEnv.qs2Hostname}`,
    eLearningUrl: `${defaultProtocol}${appEnv.eLearningHostname}`,
    appHostname: appEnv.appHostname,
    loUrl: appEnv.loUrl,
    ssoHostname: appEnv.ssoHostname,
    luminaSupportUrl: appEnv.supportPageUrl,
    keycloakUrl: appEnv.keycloakUrl,
    keycloakServerUrl: appEnv.keycloakServerUrl,
    keycloakRealm: 'lumina-sso',
    giftProductId: 52,
    allowedIframeHostnames: (appEnv.allowedIframeHostnames || '')
        .split(',')
        .map((hostname: string) => hostname.trim()),
    whitelistedReturnUrlDomains: (appEnv.whitelistedReturnUrlDomains || '')
        .split(',')
        .map((domain: string) => domain.trim()),
    accountSlugs: [
        {
            value: 'others',
            name: 'Other',
        },
        {
            value: 'ms',
            name: 'Morgan Stanley',
        },
        {
            value: 'ey',
            name: 'EY Lane4',
        },
    ],
    isMaintenanceMode: appEnv.isMaintenanceMode,
};

export default applicationEnv;