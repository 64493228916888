const env = {
    environment: 'production',
    appHostname: 'sso.v2.luminalearning.com',
    gqlGatewayHostname: 'graphql.api.luminalearning.com',
    ssoHostname: 'sso.luminalearning.com',
    practitionerHostname: 'practitioner.luminalearning.com',
    qs2Hostname: 'questionnaire.luminalearning.com',
    atsHostname: 'ats.luminalearning.com',
    financeHostname: 'finance.luminalearning.com',
    teamHostname: 'team.luminalearning.com',
    eLearningHostname: 'e-learning.luminalearning.com',
    loUrl: 'https://www.luminalearning.com',
    supportPageUrl: 'https://support.luminalearning.com',
    keycloakUrl: 'https://auth.luminalearning.com',
    keycloakServerUrl: 'https://auth.luminalearning.com',
    allowedIframeHostnames: [
        'www.luminalearning.com',
        'questionnaire.luminalearning.com',
        'e-learning.luminalearning.com',
        'questionnaire-v3.luminalearning.com',
    ].join(','),
    whitelistedReturnUrlDomains: ['luminalearning.com'].join(','),
    isMaintenanceMode: false,
};

export default env;