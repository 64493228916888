const env = {
    environment: 'staging',
    appHostname: 'sso.v2.luminalearning-nonproduction.com',
    gqlGatewayHostname: 'graphql-api.luminalearning-nonproduction.com',
    ssoHostname: 'single-sign-on.luminalearning-nonproduction.com',
    practitionerHostname: 'practitioner.luminalearning-nonproduction.com',
    qs2Hostname: 'questionnaire.luminalearning-nonproduction.com',
    atsHostname: 'applicant-tracker.luminalearning-nonproduction.com',
    financeHostname: 'finance.luminalearning-nonproduction.com',
    teamHostname: 'team.luminalearning-nonproduction.com',
    eLearningHostname: 'e-learning.luminalearning-nonproduction.com',
    loUrl: 'https://luminalearning-nonproduction.com',
    supportPageUrl: 'https://support.luminalearning.com',
    keycloakUrl: 'https://auth.luminalearning-nonproduction.com',
    keycloakServerUrl: 'https://auth.luminalearning-nonproduction.com',
    allowedIframeHostnames: [
        'luminalearning-nonproduction.com',
        'questionnaire.luminalearning-nonproduction.com',
        'e-learning.luminalearning-nonproduction.com',
        'questionnaire-v3.luminalearning-nonproduction.com',
    ].join(','),
    whitelistedReturnUrlDomains: ['luminalearning-nonproduction.com'].join(','),
    isMaintenanceMode: false,
};

export default env;