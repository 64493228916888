import {
    useState, useEffect, useCallback,
} from 'react';
import './LuminaHeader.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThLarge, faBars } from '@fortawesome/free-solid-svg-icons';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import {
    useQuery, useMutation, useLazyQuery, useApolloClient,
} from '@apollo/client';
import Button from 'react-bootstrap/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import luminaLogo from '../../assets/img/lumina-logo.png';
import applicationEnv from '../../applicationEnv';
import { initLogout, loginLuminaOnline } from '../../services/auth';
import DetectOutsideClick from '../Common/DetectOutsideClick';
import { IS_LOGGED_IN } from '../../graphql/authQuery';
import { GET_KEYCLOAK_EMAIL, UPDATE_KEYCLOAK_USER_PREFERRED_LANGUAGE } from '../../graphql/keycloakQuery';
import KnowledgeCenterNav from './KnowledgeCenterNav';
import LanguageSelection from './LanguageSelection';
import ProfileNav from './ProfileNav';
import { locales } from '../../services/intl';
import { currentLanguage } from '../../graphql/client';
import { GET_LOCALE } from '../../graphql/intlQuery';
import { decodeJwt } from '../../helpers/checkJwt';
import withRouter from '../../Hooks/withRouter';

const LuminaHeader = ({ removeCookie }: any) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const client = useApolloClient();
    const intl = useIntl();
    const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
    const [isMobileLanguageDropdownOpen, setIsMobileLanguageDropdownOpen] = useState(false);
    const [isLanguageDropdownEnabled, setIsLanguageDropdownEnabled] = useState(true);
    const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
    const [isMobileProfileDropdownOpen, setIsMobileProfileDropdownOpen] = useState(false);
    const [isNavigating, setIsNavigating] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [luminaOnlineProfiles, setLuminaOnlineProfiles] = useState<any>(null);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const { data } = useQuery(IS_LOGGED_IN);
    const { data: locale } = useQuery(GET_LOCALE);
    const [getKeycloakEmail] = useLazyQuery(GET_KEYCLOAK_EMAIL, {
        onCompleted: useCallback((response: { keycloakEmail: string }) => {
            if ('keycloakEmail' in response) {
                if (!['SERVER_ERROR', 'NOT_FOUND'].includes(response.keycloakEmail)) {
                    setEmail(response.keycloakEmail);
                }
            }
        }, []),
    });

    const [updatePreferredLanguage] = useMutation(UPDATE_KEYCLOAK_USER_PREFERRED_LANGUAGE, {
        onCompleted: useCallback(() => {
            setIsLanguageDropdownEnabled(true);
        }, []),
    });

    // Disable header
    const isHeaderDisabled = pathname.includes('/mfa/')
        || pathname.includes('/maintenance')
        || pathname.includes('/health');

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (data.isLoggedIn && !isHeaderDisabled) {
                const accessToken = localStorage.getItem('LUMINA_ACCESS_TOKEN');
                if (accessToken) {
                    const decoded = decodeJwt(accessToken);
                    if (decoded) {
                        setUsername(decoded.preferred_username);
                        if (
                            decoded.app_metadata.length > 0
                            && 'lumina_online' in decoded.app_metadata[0]
                            && 'profiles' in decoded.app_metadata[0].lumina_online
                        ) {
                            setLuminaOnlineProfiles(decoded.app_metadata[0].lumina_online.profiles);
                            getKeycloakEmail();
                        }
                    }
                }
            }
            // Default locale when logged out is set when initialising apollo client
        }

        return () => { // componentWillUnmount()
            mounted = false;
        };
    }, [pathname, data, getKeycloakEmail]); // eslint-disable-line react-hooks/exhaustive-deps

    const logout = async () => {
        setIsNavigating(true);
        navigate('/login'); // navigate to login to prevent error shows briefly after logged out
        const isLoggedOut = await initLogout(client, removeCookie);

        if (isLoggedOut) {
            // Reload to make sure all local cache is cleared
            window.location.reload();
        }
    };

    const goToPortal = () => {
        setIsNavigating(true);

        navigate('/portal');

        setIsNavigating(false);
    };

    const goToProfile = () => {
        setIsNavigating(true);

        navigate('/portal/profile');

        setIsNavigating(false);
        setIsProfileDropdownOpen(false);
    };

    const goToSupportPage = async () => {
        setIsNavigating(true);
        if (luminaOnlineProfiles !== null) {
            const luminaProfileType = ['staff', 'partners', 'practitioners', 'participants'].find(
                (profileType) => profileType in luminaOnlineProfiles && luminaOnlineProfiles[profileType].length > 0
            );

            if (luminaProfileType) {
                try {
                    await loginLuminaOnline(luminaProfileType, luminaOnlineProfiles[luminaProfileType][0]);
                } catch (error: unknown) {
                    console.error((error as Error).message);
                }
            }
        }

        window.location.href = `${applicationEnv.loUrl}/support`;
    };

    const goToLogin = () => {
        navigate('/login');
    };

    const handleLanguageChange = async (localeKey: string) => {
        localStorage.setItem('locale', localeKey);
        setIsLanguageDropdownEnabled(false);
        currentLanguage(localeKey);
        if (data.isLoggedIn) {
            updatePreferredLanguage({ variables: { preferredLanguage: localeKey } });
        } else {
            setIsLanguageDropdownEnabled(true);
        }

        setIsLanguageDropdownOpen(false);
        setIsMobileLanguageDropdownOpen(false);
    };

    if (isHeaderDisabled) {
        return <></>;
    }
    return (
        <div className="header-container">
            <Container className="header-nav-container">
                <Navbar className="lumina-header">
                    <Nav className="mr-auto left-nav">
                        <Navbar.Brand href={applicationEnv.loUrl}>
                            <img
                                src={luminaLogo}
                                className="d-inline-block align-top logo"
                                alt={intl.formatMessage({ id: 'LUMINA_HEADER.LOGO_ALT', defaultMessage: 'Lumina Learning Logo' })}
                                loading="lazy"
                                height="72"
                            />
                        </Navbar.Brand>
                        <Button onClick={() => goToPortal()} className="portal-nav">
                            <FontAwesomeIcon icon={faThLarge} size="lg" />
                            {' '}
                            <strong><FormattedMessage id="LUMINA_HEADER.PORTAL_NAV" defaultMessage="Portal" /></strong>
                        </Button>
                    </Nav>
                    <Nav className="right-nav">
                        <KnowledgeCenterNav isMobile={false} />
                        <DetectOutsideClick callback={() => setIsLanguageDropdownOpen(false)}>
                            <Nav className="language-nav">
                                <LanguageSelection
                                    isMobileMenu={false}
                                    isDropdownOpen={isLanguageDropdownOpen}
                                    isLanguageDropdownEnabled={isLanguageDropdownEnabled}
                                    setIsDropdownOpen={setIsLanguageDropdownOpen}
                                    currentLanguage={(locales as any)[locale.appLocale]}
                                    languageChangeCallback={handleLanguageChange}
                                />
                            </Nav>
                        </DetectOutsideClick>
                        <Nav className="profile-nav">
                            <ProfileNav
                                isMobile={false}
                                isLoggedIn={data.isLoggedIn}
                                isNavigating={isNavigating}
                                isProfileDropdownOpen={isProfileDropdownOpen}
                                setIsProfileDropdownOpen={setIsProfileDropdownOpen}
                                username={username}
                                email={email}
                                goToProfile={goToProfile}
                                goToSupportPage={goToSupportPage}
                                goToLogin={goToLogin}
                                logout={logout}
                            />
                        </Nav>
                        <Nav className="mobile-nav-hamburger">
                            <Button
                                size="sm"
                                variant="outline-secondary"
                                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                                aria-expanded={isMobileMenuOpen}
                                aria-label={intl.formatMessage({ id: 'LUMINA_HEADER.HAMBURGER_MENU', defaultMessage: 'Toggle Top Navigation Menu' })}
                            >
                                <FontAwesomeIcon icon={faBars} size="2x" />
                            </Button>
                        </Nav>
                    </Nav>
                </Navbar>
            </Container>
            <div className={ `mobile-nav-menu ${isMobileMenuOpen ? 'open' : ''}`}>
                <KnowledgeCenterNav isMobile={true} />
                <div className="mobile-language-nav">
                    <DetectOutsideClick callback={() => setIsMobileLanguageDropdownOpen(false)}>
                        <LanguageSelection
                            isMobileMenu={true}
                            isDropdownOpen={isMobileLanguageDropdownOpen}
                            isLanguageDropdownEnabled={isLanguageDropdownEnabled}
                            setIsDropdownOpen={setIsMobileLanguageDropdownOpen}
                            currentLanguage={(locales as any)[locale.appLocale]}
                            languageChangeCallback={handleLanguageChange}
                        />
                    </DetectOutsideClick>
                </div>
                <div className="mobile-profile-nav">
                    <ProfileNav
                        isMobile={true}
                        isLoggedIn={data.isLoggedIn}
                        isNavigating={isNavigating}
                        isProfileDropdownOpen={isMobileProfileDropdownOpen}
                        setIsProfileDropdownOpen={setIsMobileProfileDropdownOpen}
                        username={username}
                        email={email}
                        goToProfile={goToProfile}
                        goToSupportPage={goToSupportPage}
                        goToLogin={goToLogin}
                        logout={logout}
                    />
                </div>
            </div>
        </div>
    );
};

export default withRouter(LuminaHeader);
