const env = {
    environment: 'development',
    appHostname: 'sso.v2.luminalearning.local',
    gqlGatewayHostname: 'graphql.api.luminalearning.local',
    ssoHostname: 'sso.luminalearning.local',
    practitionerHostname: 'practitioner.luminalearning.local',
    qs2Hostname: 'qs2.luminalearning.local',
    atsHostname: 'ats.luminalearning.local',
    financeHostname: 'finance.luminalearning.local',
    teamHostname: 'team.luminalearning.local',
    eLearningHostname: 'e-learning.luminalearning.local',
    loUrl: 'https://www.luminalearning.local',
    supportPageUrl: 'https://support.luminalearning.com',
    keycloakUrl: 'http://auth.luminalearning.local',
    keycloakServerUrl: 'http://auth.luminalearning.local',
    allowedIframeHostnames: [
        'www.luminalearning.local',
        'questionnaire.luminalearning.local',
        'qs3.luminalearning.local',
        'e-learning.luminalearning.local',
    ].join(','),
    whitelistedReturnUrlDomains: [
        'luminalearning.com',
        'localhost',
        'lumina-nonprod.com',
        'luminalearning.local',
    ].join(','),
    isMaintenanceMode: false,
};

export default env;