import {
    useState, useEffect, useCallback, createElement, Suspense,
} from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import {
    useApolloClient, useLazyQuery, useQuery, useMutation,
} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { IntlProvider } from 'react-intl';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ErrorBoundary } from 'react-error-boundary';
import LuminaHeader from './Components/LuminaHeader';
import { initAuthenticationFromStorage, refreshAccessToken } from './services/auth';
import { IS_LOGGED_IN } from './graphql/authQuery';
import useDidUpdateEffect from './Hooks/useDidUpdateEffect';
import pages from './pages';
import applicationEnv from './applicationEnv';
import { GET_LOCALE } from './graphql/intlQuery';
import { loadLocaleMessages, selectValidLocale } from './services/intl';
import MainSidebar from './Components/MainSidebar';
import PageHeader from './Components/PageHeader';
import HealthCheckBanner from './Components/Utilities/HealthCheckBanner';
import { decodeJwt } from './helpers/checkJwt';
import { currentLanguage, decodedToken } from './graphql/client';
import { UPDATE_KEYCLOAK_USER_PREFERRED_LANGUAGE } from './graphql/keycloakQuery';
import ApplicationErrorAlert from './Components/Utilities/ApplicationErrorAlert';

const App = () => {
    const client = useApolloClient();
    const { data: localeData } = useQuery(GET_LOCALE);
    const [isAuthenticating, setIsAuthenticating] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
    const [_cookies, setCookie, removeCookie] = useCookies(['luminaAccessToken', 'luminaRefreshToken']);
    const [getLoggedInStatus, { loading }] = useLazyQuery(IS_LOGGED_IN, {
        fetchPolicy: 'network-only',
        onCompleted: useCallback((data: { isLoggedIn: boolean }) => {
            setIsLoggedIn(data.isLoggedIn);
        }, []),
    });

    const [updatePreferredLanguage] = useMutation(UPDATE_KEYCLOAK_USER_PREFERRED_LANGUAGE);

    useDidUpdateEffect(() => setIsAuthenticating(false), [isLoggedIn]);

    // Authentication
    useEffect(() => {
        let refreshJwt: NodeJS.Timeout | null = null;

        // Initial token check
        let isStorageAuthenticationValid = false;
        (async () => {
            isStorageAuthenticationValid = await initAuthenticationFromStorage(setCookie, removeCookie, client);
            getLoggedInStatus();

            // Refresh token every 15 mins (1000ms/1s * 60 * 15) to prevent timeout if user is still using application
            if (isStorageAuthenticationValid) {
                refreshJwt = setInterval(async () => {
                    await refreshAccessToken(setCookie, removeCookie, client);
                }, (1000 * 60 * 15));
            }
        })();

        return () => {
            if (refreshJwt !== null) {
                clearInterval(refreshJwt);
            }
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Set and update locale in Keycloak
        if (isLoggedIn) {
            const accessToken = localStorage.getItem('LUMINA_ACCESS_TOKEN');

            if (accessToken) {
                const decoded = decodeJwt(accessToken);
                if (decoded) {
                    decodedToken(decoded);
                    if ('client_locale' in decoded && decoded.client_locale) {
                        const validLocale = selectValidLocale(decoded.client_locale);
                        localStorage.setItem('locale', validLocale);
                        currentLanguage(validLocale);
                        updatePreferredLanguage({ variables: { preferredLanguage: validLocale } });
                    }
                }
            }
        }
    }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

    const LoadingIcon = (props: { paddingTop: number }) => (
        <div style={{ textAlign: 'center', paddingTop: `${props.paddingTop}px` }}>
            <FontAwesomeIcon icon={faCog} spin size="6x" />
        </div>
    );

    return (
        !isAuthenticating && !loading && localeData && isLoggedIn !== null
            ? <IntlProvider
                locale={selectValidLocale(localeData.appLocale)}
                messages={loadLocaleMessages(localeData.appLocale)}
                defaultLocale={applicationEnv.defaultLocale}
            >
                <ErrorBoundary FallbackComponent={ApplicationErrorAlert} >
                    <div className="App">
                        <HealthCheckBanner />
                        <BrowserRouter>
                            <LuminaHeader removeCookie={removeCookie} />
                            <Suspense fallback={<LoadingIcon paddingTop={130} />}>
                                <Routes>
                                    {/* Redirect routes */}
                                    <Route path="/" element={<Navigate to="/portal/dashboard" replace />} />
                                    <Route path ="/portal" element={<Navigate to="/portal/dashboard" replace />} />
                                    {/* https://web.dev/change-password-url/ */}
                                    <Route
                                        path="/.well-known/change-password"
                                        element={<Navigate to="/portal/security/login-details" replace />}
                                    />

                                    { pages.map((page) => {
                                        const pageProps = {
                                            pageTitle: page.title,
                                            pageName: page.name,
                                            isPartnerPage: page.requiresPartner || false,
                                            isAdminPage: page.requiresAdmin || false,
                                        };

                                        const { requiresLogin, requiresLogout } = page;
                                        const loggedInPage = requiresLogin || false;
                                        const loggedOutPage = requiresLogout || false;

                                        const renderPage = () => {
                                            const pagesOnlineOnMaintenance = [
                                                'health-check',
                                                'auth-login-frame',
                                                'mfa-iframe',
                                                'not-found',
                                                'maintenance',
                                            ];

                                            const pageContent = <>
                                                <PageHeader {...pageProps} />
                                                { typeof page.sidebar !== 'undefined' && page.sidebar
                                                    ? <Container fluid>
                                                        <Row>
                                                            <Col
                                                                md={12} lg={3} xl={2}
                                                                style={{ paddingLeft: 0, paddingRight: 0 }}
                                                                className='main-sidebar-container'
                                                            >
                                                                <MainSidebar
                                                                    isAdminPage={pageProps.isAdminPage}
                                                                    isPartnerPage={pageProps.isPartnerPage}
                                                                />
                                                            </Col>
                                                            <Col
                                                                md={12}
                                                                lg={{ span: 9, offset: 3 }}
                                                                xl={{ span: 10, offset: 2 }}
                                                                className='main-content-container'
                                                            >
                                                                { createElement(page.element, (page.props || {})) }
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                    : <>{ createElement(page.element, page.props) }</> }
                                            </>;

                                            if (
                                                applicationEnv.isMaintenanceMode
                                                && !pagesOnlineOnMaintenance.includes(page.name)
                                            ) {
                                                return <Navigate to="/maintenance" replace/>
                                            }

                                            return (loggedInPage && !isLoggedIn) || (loggedOutPage && isLoggedIn)
                                                ? <Navigate
                                                    to={`/${loggedInPage ? 'login' : 'portal/dashboard'}`}
                                                    state={{ from: window.location.pathname }}
                                                    replace
                                                />
                                                : pageContent;
                                        };

                                        return <Route
                                            key={page.name}
                                            path={page.path}
                                            element={renderPage()}
                                        />;
                                    })}
                                </Routes>
                            </Suspense>
                        </BrowserRouter>
                    </div>
                </ErrorBoundary>
            </IntlProvider>
            : <LoadingIcon paddingTop={30} />
    );
};

export default App;